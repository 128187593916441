export const parameterTypes: any[] = [
    {
      text: 'string',
      value: 0,
    },
    {
      text: 'number',
      value: 1,
    },
    {
      text: 'Term',
      value: 2,
    },
  ]

export const operators: any[] = [
    {
      text: '',
      value: undefined
    },
    {
      text: 'Starts with',
      value: 'BEGINS'
    },
    {
      text: 'Ends with',
      value: 'ENDS'
    },
    {
      text: 'Contains',
      value: 'CONTAINS'
    }
  ]