import { OldParameterValueViewModel } from "@/models/library-maintenance";
import { Dictionary } from "vue-router/types/router";
import { Region, TemplateVisitor } from "./Template";
import { parse } from "./TemplateExpressionParser";


export class ParameterEnumeratorVisitor implements TemplateVisitor {
  parameters = [] as { name: string; allowMultiple: boolean; isTerm: boolean }[];

  addParameter(name: string, allowMultiple: boolean, isTerm: boolean) {
    const existing = this.parameters.find(x => x.name == name);
    if (!existing) {
      this.parameters.push({ name, allowMultiple, isTerm })
      return;
    }

    existing.allowMultiple = existing.allowMultiple || allowMultiple;
    existing.isTerm = existing.isTerm || isTerm;
  }

  VisitText(): void {
    // noop
  }

  VisitParameter(name: string, quoted: boolean): void {
    this.addParameter(name, false, quoted);
  }

  VisitFunction(name: string, args: string[]): void {
    if (args.length > 0) {
      this.addParameter(args[0], true, true);
    }
  }

  VisitCondition(clause: string, regions: Region[]): void {
    regions.forEach(region => region.Accept(this));
  }
}

class TemplateRendererVisitor implements TemplateVisitor {
  output = '';
  messages = [] as string[];

  functionLookup = {
    OR: (args: string[]) => this.QuoteJoin(" OR ", this.ResolveArrayParameter(args[0])),
    AND: (args: string[]) => this.QuoteJoin(" AND ", this.ResolveArrayParameter(args[0])),
    XOR: (args: string[]) => this.QuoteJoin(" XOR ", this.ResolveArrayParameter(args[0]))
  } as {
    [key: string]: (args: string[]) => string;
  };

  constructor(private parameters: Dictionary<OldParameterValueViewModel[]>) { }

  VisitText(text: string): void {
    this.output += text;
  }

  VisitParameter(name: string, quoted: boolean): void {
    const parameter = this.ResolveSingleParameter(name);

    if (!parameter) return;

    if (quoted) {
      this.output += this.RenderQuotedParameter(parameter);
    } else {
      this.output += parameter.value;
    }
  }

  VisitFunction(name: string, args: string[]): void {
    const fn = this.functionLookup[name.toUpperCase()]

    if (!fn) return;

    this.output += fn(args);
  }

  VisitCondition(clause: string, regions: Region[]): void {
    if (!this.ParameterExists(clause)) return;

    regions.forEach(region => region.Accept(this));
  }

  QuoteJoin(separator: string, parameters: OldParameterValueViewModel[]): string {
    const str = parameters.map(x => this.RenderQuotedParameter.call(this, x)).join(separator);

    return `(${str})`;
  }

  RenderQuotedParameter(parameter: OldParameterValueViewModel) {
    if (parameter["is-regex"]) {
      let regex = `/${parameter.value.replace("/", "\\/")}/`;
      if (parameter['is-suppressed']) {
        regex = regex + '?';
      }

      return regex;
    }

    let quoted = `'${parameter.value.replace("'", "\\'")}'`;

    if (parameter.unordered) {
      quoted = quoted + "#";
      if (quoted.trim().split(/\s+/).length === 1) {
        this.messages.push('Unordered applied to parameter ' +
        parameter.name +
        ' (with value ' +
          parameter.value + ')' +
        ' will not affect outcome of match');
      }
    }

    if (parameter.fuzziness != 0) {
      const similarity = 100 - parameter.fuzziness;
      if (parameter.fuzziness > 30)
      {
        this.messages.push('Parameter ' +
          parameter.name +
          ' (with value ' +
          parameter.value + ')' +
          ' has a fuzziness greater than 30%')
      }

      const fuzzinessFactor = "0." + similarity.toString().padStart(2, '0');
      quoted = quoted + "~" + fuzzinessFactor;
    }

    if (parameter['is-suppressed']) {
      quoted = quoted + "?";
    }

    if (parameter['operator']) {
      quoted = parameter['operator'] + quoted;
    }

    if (parameter.proximity > 0){
      quoted = quoted + "@" + parameter.proximity;
    }

    return quoted;
  }

  ParameterExists(name: string): boolean {
    const parameter = this.ResolveParameter(name);
    return parameter != undefined && parameter.length > 0;
  }

  ResolveParameter(name: string): OldParameterValueViewModel[] | undefined {
      const key = name.trim();

      return this.parameters[key];
  }

  ResolveSingleParameter(name: string): OldParameterValueViewModel | undefined {
      const value = this.ResolveParameter(name);

      if (value === undefined || value.length == 0) return undefined;

      return value[0];
  }

  ResolveArrayParameter(name: string): OldParameterValueViewModel[] {
      const value = this.ResolveParameter(name);

      if (value === undefined) return [];

      return value;
  }
}

export class TemplateRenderer {
  static render(template: string, parameters: Dictionary<OldParameterValueViewModel[]>) {
    const model = parse(template);
    const renderer = new TemplateRendererVisitor(parameters);

    model.Accept(renderer);

    return  {
      output: renderer.output
        .split('\n')
        .filter(x => x.trim().length > 0)
        .join('\n'),
      messages: renderer.messages
    }
  }
}
