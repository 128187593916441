


































































































































































































































import { Field, FieldList } from '@/models/form';
import {
  Component,
  Prop,
  Ref,
  Vue,
  Watch,
} from 'vue-property-decorator';
import * as validation from '@/models/validation';
import MetadataEditor from '@/components/library-maintenance/metadata-editor.vue';
import EcTextField from 'common-components/src/components/form/ec-text-field.vue';
import HdQueryEditor from '@/components/form/hd-query-editor.vue';
import { Template, TemplateUpsertModel, TemplateParameter } from '@/models/library-maintenance.d';
import { TableHeader } from '@/components/layout/models/table.d';
import { ApiError } from '@/models/hal';
import dayjs from "dayjs";
import { parse as templateParse } from '@/parser/TemplateExpressionParser';
import { TemplateModel } from '@/parser/Template';
import { ParameterEnumeratorVisitor } from '@/parser/TemplateVisitors';
import { parameterTypes, operators } from '@/models/dropdowns';

@Component({
  components: {
    MetadataEditor,
    EcTextField,
    HdQueryEditor,
  },
})
export default class TemplateEditor extends Vue {
  @Prop({ default: () => ({}) }) value!: Template;

  @Ref() form!: HTMLFormElement;

  @Ref() queryEditor!: HdQueryEditor;

  @Prop({ default: () => ([]) }) apiErrors!: ApiError[];

  get editorOptions() {
    return {
      validate: validation.templateExpression,
      language: 'hdtl',
      readOnly: false,
    };
  }

  parameterError = '';

  parameterHeaders: TableHeader[] = [
    {
      text: 'Name',
      value: 'name',
      sortable: false,
    },
    {
      text: 'Default Value',
      value: 'default-value',
      sortable: false,
    },
    {
      text: 'Data Type',
      value: 'type',
      sortable: false,
    },
    {
      text: 'Allow Multiple',
      value: 'allow-multiple',
      sortable: false,
    },
    {
      text: "Operator",
      value: "default-operator",
      sortable: false,
      width: '160px',
      class: "text-no-wrap px-1"
    },
    {
      text: 'Suppressed',
      value: 'default-is-suppressed',
      sortable: false,
      width: '1px',
      align: 'center',
      class: 'text-no-wrap px-1'
    },
    {
      text: 'Regex',
      value: 'default-is-regex',
      sortable: false,
      width: '1px',
      align: 'center',
      class: 'text-no-wrap px-1'
    },
    {
      text: 'Unordered',
      value: 'default-unordered',
      sortable: false,
      width: '1px',
      align: 'center',
      class: 'text-no-wrap px-1'
    },
    {
      text: 'Fuzziness',
      value: 'default-fuzziness',
      sortable: false,
      width: '140px',
      align: 'center',
      class: 'text-no-wrap px-1'
    },
    {
      text: 'Proximity',
      value: 'default-proximity',
      sortable: false,
      width: '140px',
      align: 'center',
      class: 'text-no-wrap px-1'
    },
    {
      text: '',
      value: 'remove',
      sortable: false,
    },
  ];

  types: any[] = parameterTypes;

  operators: any[] = operators;

  fieldList = new FieldList({
    name: new Field(
      '',
      'Name',
      [
        validation.required(),
        validation.maxLength(50),
      ],
      [],
    ),
    description: new Field(
      '',
      'Description',
      [
        validation.maxLength(200),
      ],
      [],
    ),
    condition: new Field(
      '',
      'Condition',
      [
        validation.required(),
      ],
      [],
      'Condition'
    ),
    metadata: new Field(
      {},
      'Metadata',
    ),
    parameters: new Field(
      [],
      'Parameters',
      [],
    ),
  });

  addParameter() {
    const newParameter: TemplateParameter = {
         name: '',
        'default-value': '',
        type: 0,
        'allow-multiple': false,
        'default-unordered': false,
        'default-fuzziness': 0.0,
        'default-is-regex': false,
        'default-is-suppressed': false,
        'default-operator': undefined,
        'default-proximity': 0,
    }
    this.fields.parameters.value.push(
      newParameter
    );
  }

  parseParameters() {
    try {
      const compoundTemplate = this.fields.condition.value + JSON.stringify(this.fields.metadata.value);
      const model = templateParse(compoundTemplate) as TemplateModel;
      const visitor = new ParameterEnumeratorVisitor();

      model.Accept(visitor);

      this.fields.parameters.value = visitor.parameters.map(p =>
        {
          const parameter: TemplateParameter = {
            name: p.name,
            'allow-multiple': p.allowMultiple,
            'default-value': '',
            'type': p.isTerm ? 2 : 0,
            'default-unordered': false,
            'default-fuzziness': 0.0,
            'default-is-regex': false,
            'default-is-suppressed': false,
            "default-operator": undefined,
            'default-proximity': 0,
          }

          return parameter;
        }
      );
    } catch {
      this.fields.condition.errors?.push('Invalid template');
    }
  }

  get fields() { return this.fieldList.fields; }

  @Watch('value', { immediate: true })
  onValueChanged() {
    this.reset(true);
  }

  @Watch('apiErrors')
  onErrorsChanged() {
    this.fieldList.addAllErrors(this.apiErrors);
    this.parameterError = this.apiErrors
      .filter((x) =>
        x.property.includes('Parameters') ||
        x.property.includes('Condition')
      )
      .map((x) => x.message)
      .join(', ');
  }

  removeParameter(index: number){
    this.fields.parameters.value.splice(index, 1);
  }

  canRemoveParameter(): boolean{
    return this.fields.parameters.value.length > 1;
  }

  getLastCascadeUpdatedDate(){
    return this.value['last-cascade-update'] === undefined
      ? 'Never'
      : dayjs.utc(this.value['last-cascade-update']).local().format('YYYY-MM-DD HH:mm:ss')
  }
  reset(onValueChanged: boolean) {
    const clone = JSON.parse(JSON.stringify(this.value)) as Template;

    this.fields.name.value = clone.name;
    this.fields.description.value = clone.description;
    this.fields.condition.value = clone.condition;
    this.fields.metadata.value = clone.metadata;
    this.fields.parameters.value = clone.parameters.map((x: TemplateParameter) => ({
      ...x,
      'default-fuzziness': 100 - (x['default-fuzziness'] * 100)
    }));

    if (!onValueChanged) {
      this.validateParameters(clone.parameters);
    }
  }

  validateParameters(parameters: TemplateParameter[]): boolean {
    if (parameters.length === 0) {
      this.parameterError = 'Parameters must have at least 1 item';
      return false;
    }

    if (parameters.filter((x) => x.name === '').length > 0) {
      this.parameterError = 'Parameter(s) must have a name.';
      return false;
    }

    this.parameterError = '';
    return true;
  }

  fetch(
    callback: (template: TemplateUpsertModel) => void,
    validationCallback: () => void,
  ) {
    const conditionValid = validation.templateExpression(this.fields.condition.value, '');
    const valid = this.form.validate()
      && conditionValid
      && !this.queryEditor.checkZeroWidthSpace()
      && this.validateParameters(this.fields.parameters.value);

    if (!conditionValid) {
      this.fields.condition.errors?.push('Invalid template');
    }

    if (!valid) {
      validationCallback();
      return;
    }

    const updatedTemplate: TemplateUpsertModel = {
      name: this.fields.name.value,
      description: this.fields.description.value,
      condition: this.fields.condition.value,
      metadata: this.fields.metadata.value,
      parameters: this.fields.parameters.value.map((x: TemplateParameter) => ({
        ...x,
        'default-fuzziness': (100 - x['default-fuzziness']) / 100
      })),
    };

    callback(updatedTemplate);
  }

  checkZeroWidthSpace(): boolean {
    return this.queryEditor.checkZeroWidthSpace();
  }
}
