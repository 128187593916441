












































































import {
  Component, Inject, Vue, Watch, Ref,
} from 'vue-property-decorator';

import TemplateService from '@/services/template-service';
import LibraryService from '@/services/library-service';
import Card from '@/components/material/Card.vue';
import { SnackbarOptions } from '@/models/form';
import EcSnackBar from 'common-components/src/components/form/ec-snack-bar.vue';
import TemplateEditor from '@/components/library-maintenance/template-editor.vue';
import { Library, Template, TemplateUpsertModel } from '@/models/library-maintenance.d';
import { backOr } from '@/router';

import { getModule } from 'vuex-module-decorators';
import AppState from '@/store/modules/app-module';
import { ApiError } from '../models/hal.d';

const appState = getModule(AppState);

@Component({
  components: {
    Card,
    TemplateEditor,
    EcSnackBar,
  },
})

export default class TemplateView extends Vue {
  @Inject() TemplateService!: TemplateService;

  @Inject() LibraryService!: LibraryService;

  @Ref() editor!: TemplateEditor;

  libraryId!: string;

  library: Library = {} as Library;

  libraryTitle = '';

  templateId?: string;

  template: Template = {
    parameters: [],
  } as unknown as Template;

  get editing() { return Boolean(this.templateId); }

  metadataKeys = [
    { header: 'Select a predfined metadata key or create a custom one' },
    { text: 'category' },
    { text: 'sub-category' },
    { text: 'priority' },
  ];

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  apiErrors: ApiError[] = [];

  loading = false;

  @Watch('$route', { immediate: true })
  onRouteChanged(value: any): Promise<void> {
    this.libraryId = value.params.libraryId;
    this.templateId = value.params.templateId;

    if (!this.libraryId) {
      return Promise.reject(new Error('library id is undefined'));
    }

    this.loading = true;
    this.LibraryService
      .readSingle(this.libraryId)
      .then((library) => {
        this.library = library;
        this.libraryTitle = `Library: ${this.library.name}`;
      });

    if (!this.templateId) {
      this.loading = false;
      return Promise.resolve();
    }

    return this.TemplateService
      .readSingle(this.libraryId, this.templateId)
      .then((template) => {
        this.template = template;
        this.loading = false;
      });
  }

  get buttonTitle() {
    return `Update ${this.template['number-of-rules']} ${this.template['number-of-rules'] != 1 ? 'Rules' : 'Rule'}`;
  }

  get headerTitle() {
    return  this.templateId ? `Used by ${this.template['number-of-rules']}
          ${this.template['number-of-rules'] != 1 ? 'Rules' : 'Rule'}` : '';
  }

  @Watch('template')
  onTemplateUpdated() {
    appState.setTitle(`Template: ${this.template?.name}`);
  }

  back() {
    backOr({ name: 'templates', params: { libraryId: this.libraryId } });
  }

  async cascadeUpdate() {
    this.$router.push({ name: 'template-operations', params: { libraryId: this.libraryId, templateId: this.templateId ?? '' } });
  }

  save(templateUpsert: TemplateUpsertModel) {
    if (!this.templateId) {
      this.snackbarOptions = EcSnackBar.makeProgressOptions('Creating template ...');
      this.TemplateService.createTemplate(this.libraryId, templateUpsert)
        .then((template) => {
          this.snackbarOptions.value = false;
          this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions('Successfully created'); });

          this.$router.replace({ name: 'template', params: { libraryId: this.libraryId, templateId: template.id } });
        })
        .catch((http) => {
          this.snackbarOptions = EcSnackBar.makeDefaultOptions();
          this.apiErrors = http.response?.data?.errors || [];
        });

      return;
    }

    this.snackbarOptions = EcSnackBar.makeProgressOptions('Saving template ...');
    this.TemplateService.updateTemplate(this.libraryId, this.templateId, templateUpsert)
      .then((template) => {
        this.snackbarOptions.value = false;
        this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions('Successfully updated'); });

        this.template = template;
      })
      .catch((http) => {
        this.snackbarOptions = EcSnackBar.makeDefaultOptions();
        this.apiErrors = http.response?.data?.errors || [];
      });
  }

  formValidationError() {
    if (this.editor.checkZeroWidthSpace()) {
      this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions('Zero width spaces are not allowed');
    } else {
      this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions('Please correct any errors before saving');
    }
  }

  async deleteTemplate() {
    if (!this.templateId) return;

    try {
      const text = 'If you delete this template, it will no longer be possible to update the rules generated from it in one action';
      appState.openDialog({
        title: 'Delete Template?',
        text: `<p>Are you sure?</p><p>${text}</p>`,
        actions: [{
          name: 'Delete',
          color: 'warning',
          handler: async () => {
            this.snackbarOptions = EcSnackBar.makeProgressOptions('Deleting template ...');
            this.TemplateService.deleteTemplate(this.libraryId, this.templateId ?? "")
              .then(() => {
                this.snackbarOptions.value = false;
                this.back();
                }
              )
              .catch((error: Error) => {
                this.snackbarOptions.value = false;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete template: ${error.message}`); });
              }
            )
          },
        },
        {
          name: 'Cancel',
          color: 'primary',
          handler: () => Promise.resolve(false),
        }],
      });
    } catch (error) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete template: ${error}`); });
    }
  }
}

