var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-container',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('ec-text-field',{staticClass:"name-input",attrs:{"field":_vm.fields.name}})],1),_c('v-col',{attrs:{"md":"8"}},[_c('ec-text-field',{staticClass:"description-input",attrs:{"field":_vm.fields.description}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('hd-query-editor',{ref:"queryEditor",attrs:{"field":_vm.fields.condition,"queryExpressionMessage":"Condition must be a valid query expression","editorOptions":_vm.editorOptions}})],1)],1),_c('v-row',[_c('v-col',[_c('metadata-editor',{staticClass:"metadata-input",model:{value:(_vm.fields.metadata.value),callback:function ($$v) {_vm.$set(_vm.fields.metadata, "value", $$v)},expression:"fields.metadata.value"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-label',[_vm._v("Parameters")]),_c('v-data-table',{attrs:{"headers":_vm.parameterHeaders,"items":_vm.fields.parameters.value,"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":""},model:{value:(item['name']),callback:function ($$v) {_vm.$set(item, 'name', $$v)},expression:"item['name']"}})]}},{key:"item.default-value",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":""},model:{value:(item['default-value']),callback:function ($$v) {_vm.$set(item, 'default-value', $$v)},expression:"item['default-value']"}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"hide-details":"","items":_vm.types},model:{value:(item['type']),callback:function ($$v) {_vm.$set(item, 'type', $$v)},expression:"item['type']"}})]}},{key:"header.allow-multiple",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_c('p',[_vm._v("Allows the user to enter multiple values for this parameter.")]),_c('p',[_vm._v("Used in templates with functions like "),_c('code',[_vm._v("OR")]),_vm._v(" and "),_c('code',[_vm._v("AND")])]),_c('p',{staticClass:"mb-0"},[_vm._v("e.g. "),_c('code',{pre:true},[_vm._v("description:{{ OR(Param) }}")])])])]}},{key:"item.allow-multiple",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"auto","color":"darkgrey"},model:{value:(item['allow-multiple']),callback:function ($$v) {_vm.$set(item, 'allow-multiple', $$v)},expression:"item['allow-multiple']"}})]}},{key:"header.default-operator",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_c('p',[_vm._v("Sets optional operator type to use (starts with (BEGINS), ends with (ENDS) or contains (CONTAINS)).")])])]}},{key:"item.default-operator",fn:function(ref){
var item = ref.item;
return [(item.type == 2)?_c('v-select',{attrs:{"items":_vm.operators,"hide-details":""},model:{value:(item['default-operator']),callback:function ($$v) {_vm.$set(item, 'default-operator', $$v)},expression:"item['default-operator']"}}):_vm._e()]}},{key:"header.default-is-suppressed",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_c('p',[_vm._v("Marks a given term parameter as suppressed.")]),_c('p',[_vm._v("This will be used by the rule but not produce a hit entry.")])])]}},{key:"item.default-is-suppressed",fn:function(ref){
var item = ref.item;
return [(item.type == 2)?_c('v-switch',{attrs:{"hide-details":""},model:{value:(item['default-is-suppressed']),callback:function ($$v) {_vm.$set(item, 'default-is-suppressed', $$v)},expression:"item['default-is-suppressed']"}}):_vm._e()]}},{key:"header.default-is-regex",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_vm._v(" Marks this parameter as a regular expression by default. ")])]}},{key:"item.default-is-regex",fn:function(ref){
var item = ref.item;
return [(item.type == 2)?_c('v-switch',{attrs:{"hide-details":""},model:{value:(item['default-is-regex']),callback:function ($$v) {_vm.$set(item, 'default-is-regex', $$v)},expression:"item['default-is-regex']"}}):_vm._e()]}},{key:"header.default-unordered",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_vm._v(" When enabled, the term will match regardless of the order of the words. ")])]}},{key:"item.default-unordered",fn:function(ref){
var item = ref.item;
return [(item.type == 2)?_c('v-switch',{attrs:{"hide-details":""},model:{value:(item['default-unordered']),callback:function ($$v) {_vm.$set(item, 'default-unordered', $$v)},expression:"item['default-unordered']"}}):_vm._e()]}},{key:"header.default-fuzziness",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_vm._v(" Specifies a default fuzziness for this parameter ")])]}},{key:"item.default-fuzziness",fn:function(ref){
var item = ref.item;
return [(item.type == 2)?_c('v-text-field',{attrs:{"type":"number","suffix":"%","hide-details":"","min":"0","max":"100"},model:{value:(item['default-fuzziness']),callback:function ($$v) {_vm.$set(item, 'default-fuzziness', $$v)},expression:"item['default-fuzziness']"}}):_vm._e()]}},{key:"header.default-proximity",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_vm._v(" Specifies a default proximity for this parameter ")])]}},{key:"item.default-proximity",fn:function(ref){
var item = ref.item;
return [(item.type == 2)?_c('v-text-field',{attrs:{"type":"number","hide-details":"","min":"0","max":"100"},model:{value:(item['default-proximity']),callback:function ($$v) {_vm.$set(item, 'default-proximity', $$v)},expression:"item['default-proximity']"}}):_vm._e()]}},{key:"item.remove",fn:function(ref){
var index = ref.index;
return [(_vm.canRemoveParameter())?_c('v-btn',{attrs:{"fab":"","elevation":"1","color":"warning","x-small":""},on:{"click":function($event){return _vm.removeParameter(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}])},[_c('template',{slot:"top"},[_c('v-btn',{on:{"click":function($event){return _vm.addParameter()}}},[_vm._v(" Add parameter ")]),_c('v-btn',{staticClass:"mx-3",on:{"click":function($event){return _vm.parseParameters()}}},[_vm._v(" Parse from template ")])],1)],2),_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message message-transition-enter-to"},[_vm._v(" "+_vm._s(_vm.parameterError)+" ")])])])],1)],1),(this.value.id)?_c('v-row',[_c('v-col',{staticClass:"text-right"},[_vm._v(" Last Cascade Update: "+_vm._s(_vm.getLastCascadeUpdatedDate())+" ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }